import { FunctionComponent } from "react";

interface ButtonProps {
  ctaText: string;
  onClick: () => any;
  variant?: string;
  disabled?: boolean;
  className?: string;
}

export enum ButtonVariantTypes {
  SECONDARY = "secondary",
  PRIMARY = "primary"
}

const Button: FunctionComponent<ButtonProps> = ({
  ctaText,
  onClick,
  variant,
  disabled = false,
  className,
}) => {
  const getVariantCss = () => {
    if (disabled) {
      return "bg-white text-[#8E8E8E] border border-[#8E8E8E]";
    }
    switch (variant) {
      case ButtonVariantTypes.SECONDARY:
        return "bg-transparent text-primary border border-primary";
      default:
        return "bg-primary text-white border border-primary";
    }
  };

  return (
    <button
      type="button"
      onClick={() => (!disabled && onClick())}
      className={`w-full py-3 font-bold text-center shadow rounded px-4 ${getVariantCss()} ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } ${className}`}
    >
      {ctaText}
    </button>
  );
};

export default Button;
