import axios from "axios";
import { isClient } from "lib/helpers/utils";
import { destroyCookie } from "nookies";
import { useCallback } from "react";
import { segmentReset } from "scripts/segment";
import { localStorageUtils } from "utils/storageUtils";

const useLogin = () => {
  const logout = useCallback(async (redirectUrl = "/") => {
    try {
      localStorageUtils?.setItem("whatsappConsentAsked", "false");
      localStorageUtils?.removeItem("applicationSlug");
      segmentReset();
      destroyCookie(null, "userContext");
      await axios.post("/api/logout");
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      if (isClient()) {
        window.open(redirectUrl, "_self");
      }
    }
  }, []);
  return { logout };
};

export default useLogin;
