import { FunctionComponent, ReactNode } from "react";
import Head from "next/head";
import dynamic from "next/dynamic";
import Header from "components/common/Layout/Header";
import Footer from "components/common/Layout/Footer";
import MainFooter from "components/common/Layout/MainFooter";

const LandingPageSchema = dynamic(
  () => import("components/common/Layout/SeoSchemaComponents")
);
interface Props {
  children: ReactNode;
  showAvatar?: boolean;
  title?: string;
  isLandingPage?: boolean;
  faq?: Array<Record<string, string>>;
}

const Layout: FunctionComponent<Props> = ({
  children,
  showAvatar,
  title = "",
  isLandingPage = false,
  faq = [],
}) => (
  <div className="overflow-visible font-sans">
    <Head>
      <title>{`${title} | Leap Finance`}</title>
      {isLandingPage && <LandingPageSchema faq={faq} />}
    </Head>
    <Header showAvatar={showAvatar} isLandingPage={isLandingPage} />
    <main className="h-full">{children}</main>
    {isLandingPage ? <MainFooter /> : <Footer />}
  </div>
);

export default Layout;
