import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import basePath from "lib/helpers/basePath";
import Paper from "@mui/material/Paper";
import Button from "components/Inputs/Button";
import Collapse from "@mui/material/Collapse";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { trackClick } from "scripts/segment";
import { pageName } from "utils/events_utils";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      color: "#443eff",
      backgroundColor: "#e1e0ff",
      border: "none",
      boxShadow: "none",
      borderRadius: "0px",
      textAlign: "center",
      lineHeight: "24px",
      display: "grid",
    },
    collapse: {
      position: "absolute",
      left: "0",
      top: "72px",
      width: "100%",
    },
  }));

const LandingPageNavToggle = () => {
  const [showNavBar, setShowNavBar] = useState(false);
  const classes = useStyles();

  return (
    <div className="md:hidden">
      <div
        className={`rounded ${
          showNavBar ? "bg-[#e1e0ff]" : ""
        } h-[40px] w-[60px] flex items-center justify-center`}
      >
        <Image
          width={24}
          height={24}
          src={`${basePath}/assets/icons/nav-bar-hamburger.svg`}
          onClick={() => setShowNavBar(!showNavBar)}
          alt="Hamburger icon"
        />
      </div>
      <Collapse
        in={showNavBar}
        timeout={400}
        className={classes.collapse}
        style={{ transformOrigin: "0 0 0" }}
      >
        <Paper elevation={4} className={classes.paper}>
          <Link
            href="#my-eligibility-id"
            rel="noopener noreferrer"
            className="py-[21px]"
            onClick={() => {
              trackClick(pageName.REFI_LANDING_PAGE, {
                widgetName: "Navbar",
                widgetFormat: "Banner",
                contentName: "Check My Eligibility",
                contentFormat: "Link",
              });
              setShowNavBar(!showNavBar);
            }}
          >
            Check My Eligibility
          </Link>
          <Button
            ctaText="Start My Application"
            className="text-[13px] px-6"
            onClick={() => {
              if (window) {
                window.open("/refinance/refi", "_self");
              }
            }}
          />
        </Paper>
      </Collapse>
    </div>
  );
};

export default LandingPageNavToggle;
