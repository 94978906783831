import { FunctionComponent } from "react";
import Image from "next/image";
import basePath from "lib/helpers/basePath";

const Footer: FunctionComponent = () => (
  <footer className="bg-gray-100 px-4">
    <div className="max-w-6xl mx-auto bg-gray-100 text-xs text-gray-1110">
      <div className="flex flex-col md:flex-row md:justify-between md:items-center m-auto py-8 md:px-0 px-4">
        <div className="flex flex-col items-start pb-4 md:pb-0 md:flex-row md:pr-16 mb-4 lg:mt-0 md:mb-0">
          <Image
            width={27}
            height={33}
            src={`${basePath}/assets/images/logo-light.svg`}
            alt="footer-icon"
          />
          <p className="md:pl-3 w-11/12 md:w-full pt-4 md:pt-0">
            Leap Finance loans are originated by Leap Inc.,
            {" "}
            <a href={`${basePath}/assets/docs/money-hop-terms-and-conditions.pdf`} download className="underline">
              Terms and conditions
            </a>
            {" "}
            apply. To qualify for a Leap loan you must reside in an eligible
            state and meet Leap&apos;s underwriting requirements. Not all
            borrowers receive the lowest rate. Rate and terms are subject to
            change at anytime without notice and are subject to state
            restrictions.
          </p>
        </div>
        <div className="align-top text-sm">
          <span className="text-gray-1110 font-bold">Reach out to us at</span>
          <p className="text-xs my-1">
            <a
              href="mailto:contact@leapfinance.com"
              target="_blank"
              rel="noreferrer"
            >
              <span className="text-gray-1110 hover:text-primary underline">
                contact@leapfinance.com
              </span>
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
