import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Link from "next/link";
import { trackClick } from "scripts/segment";
import basePath from "lib/helpers/basePath";
import Button from "components/Inputs/Button";
import Image from "next/image";
import LandingPageNavToggle from "components/common/Layout/LandingPageNavToggle";
import { pageName } from "utils/events_utils";
import useLogin from "lib/hooks/useLogin";

interface Props {
  showAvatar?: boolean;
  isLandingPage?: boolean;
}

const Header: React.FC<Props> = ({
  showAvatar = true,
  isLandingPage = false,
}) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);
  const { logout } = useLogin();

  const useStyles = makeStyles(() =>
    createStyles({
      small: {
        width: 26,
        height: 26,
      },
    }));

  const classes = useStyles();

  return (
    <header className="sticky top-0 bg-white z-50">
      <nav className="max-w-6xl mx-auto p-4 md:px-0">
        <div className="flex justify-between items-center md:mx-4">
          <Link href="https://leapfinance.com/" className="flex items-center cursor-pointer">
            <Image
              src={`${basePath}/assets/company_logo/new-footer-logo.svg`}
              alt="Leap Finance Logo"
              width={200}
              height={45}
              className="w-[150px] md:w-[200px]"
            />
          </Link>
          {showAvatar && (
            <div className="inline-block">
              <button
                type="button"
                onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                className="flex items-center"
              >
                <div className="inline-block avatar-holder rounded-full border-2 align-middle mr-2">
                  <Avatar
                    className={classes.small}
                    src={`${basePath}/assets/images/icon-user.svg`}
                  />
                </div>
                <span className="ml-2">
                  <img
                    className="inline-block"
                    src={`${basePath}/assets/images/bottom-carat.svg`}
                    alt="Profile open"
                  />
                </span>
              </button>
              {profileMenuOpen && (
                <div className="w-full block bg-white p-2 fixed md:top-10 md:w-auto rounded mt-2 z-1 shadow -ml-16">
                  <div className="text-base text-gray-1010 leading-loose">
                    <Link
                      href="/refi"
                      className="block pl-2 pr-16 hover:bg-gray-1120 cursor-pointer"
                    >
                      Applications
                    </Link>
                    <a
                      onClick={() => logout()}
                      className="block pl-2 pr-16 hover:bg-gray-1120 cursor-pointer"
                    >
                      Logout
                    </a>
                  </div>
                </div>
              )}
            </div>
          )}
          {isLandingPage && (
            <>
              <LandingPageNavToggle />
              <div className="hidden md:flex gap-4">
                <div>
                  <Link href="#my-eligibility-id" scroll={false}>
                    <button
                      type="button"
                      className="font-normal rounded text-[13px] leading-[17px] py-3 px-6 border border-primary text-primary"
                      onClick={() => {
                        trackClick(pageName.REFI_LANDING_PAGE, {
                          widgetName: "Navbar",
                          widgetFormat: "Banner",
                          contentName: "Check My Eligibility",
                          contentFormat: "Link",
                        });
                      }}
                    >
                      Check My Eligibility
                    </button>
                  </Link>
                </div>
                <div>
                  <Button
                    ctaText="Start My Application"
                    className="text-[13px] px-6"
                    onClick={() => {
                      trackClick(pageName.REFI_LANDING_PAGE, {
                        widgetName: "Navbar",
                        widgetFormat: "Banner",
                        contentName: "Start My Application",
                        contentFormat: "Button",
                      });
                      if (window) {
                        window.open("/refinance/refi", "_self");
                      }
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;
