export const companyContacts = {
  link: {
    phone: "tel:+919840380211",
    whatsApp: "https://api.whatsapp.com/send?phone=919840380211",
    email: "mailto:ashutosh.jain@leapfinance.com",
  },
  display: {
    phone: "+91 98403 80211",
    whatsApp: "+91 98403 80211",
    email: "ashutosh.jain@leapfinance.com",
  },
};
