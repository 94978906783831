import basePath from "lib/helpers/basePath";

export const refinanceSteps = [
  "Start your application by entering your personal, academic and cosigner details",
  "Get a loan offer based on your profile",
  "Accept the offer and upload your address details along with important documents",
  "Get sanction letter, and sign the contract",
  "Get the money credited to your US Bank Account!",
];

export const whyChooseUs = [
  {
    title: "Fixed Interest Rate",
    description: `Get the same interest rate for the entire tenure. \nNo fluctuations, ever.`,
    image: `${basePath}/assets/icons/percentage-icon.svg`,
    alt: "percentage icon",
  },
  {
    title: "Build Strong USA Credit History",
    description:
      "Having a strong credit history will let you get \ncredit cards and loans easily.",
    image: `${basePath}/assets/icons/bill-history.svg`,
    alt: "bill history icon",
  },
  {
    title: "No Prepayment Charges",
    description:
      "Close your loan faster by prepaying without any \nadditional fees.",
    image: `${basePath}/assets/icons/prepayment-icon.svg`,
    alt: "prepayment icon",
  },
  {
    title: "Apply from the US",
    description:
      "Refinance your loan while in the US on a visa,\nwithout a green card.",
    image: `${basePath}/assets/icons/apply-us-icon.svg`,
    alt: "apply to US icon",
  },
  {
    title: "No collateral worries",
    description:
      "Refinancing with Leap allows you to free up any\ncollateral pledged initially.",
    image: `${basePath}/assets/icons/no-collateral-icon.svg`,
    alt: "no collateral worries icon",
  },
  {
    title: "24x7 Support",
    description: "Get dedicated help in your refinancing process,\nanytime.",
    image: `${basePath}/assets/icons/support-icon.svg`,
    alt: "support icon",
  },
];

export const testimonialsReviews: any[] = [
  {
    review:
      "When I refinanced my loan, Leap paid off my current student loan and gave me a new collateral-free loan at a much lower interest rate. I got a new loan at just 7.7% (I was paying 13.5% on my old loan 🤯). I still can't believe it!",
    name: "Abhishek Rai",
    collegeName: "UNIVERSITY OF TEXAS",
    rating: 5,
    ratingImage: `${basePath}/assets/icons/star-icon.svg`,
    socialProof: [
      {
        link: "https://www.linkedin.com/in/abhishek-rai-utd/",
        image: `${basePath}/assets/icons/linkedin.svg`,
        alt: "linkedin icon",
      },
    ],
  },

  {
    review:
      "They paid off my old loan and gave me a new one at 8.5% interest, plus it is collateral-free, Which works out great for me. The interest rate is fixed hence it covers me from the expected market fluctuations also.",
    name: "Nilav Bharatkumar Vaghasiya",
    collegeName: "UNIVERSITY OF TEXAS",
    rating: 5,
    ratingImage: `${basePath}/assets/icons/star-icon.svg`,
    socialProof: [
      {
        link: "https://www.linkedin.com/in/nilav-vaghasiya/",
        image: `${basePath}/assets/icons/linkedin.svg`,
        alt: "linkedin icon",
      },
    ],
  },
];

export const eligibilityPoints = [
  "Working Professionals on payroll",
  "No bar on course & college",
];

export const statsImages = [
  {
    title: "175Mn",
    description: "Loans funded",
    image: `${basePath}/assets/icons/collateral_free_loan_icon.svg`,
    alt: "collateral free loan icon",
  },
  {
    title: "3000+",
    description: "Students funded",
    image: `${basePath}/assets/icons/students_dummy_dp.svg`,
    alt: "students dummy Dp icon",
  },
];

export const supportersImage = [
  {
    image: `${basePath}/assets/icons/owl_ventures.svg`,
    alt: "owl ventures icon",
  },
  {
    image: `${basePath}/assets/icons/harvard_management_company.svg`,
    alt: "harvard management company icon",
  },
  {
    image: `${basePath}/assets/icons/sequoia.svg`,
    alt: "sequoia icon",
  },
  {
    image: `${basePath}/assets/icons/jungle.svg`,
    alt: "jungle icon",
  },
];

export const footerLinks = {
  resources: [
    {
      text: "Education Loan Eligibility",
      link: "https://leapfinance.com/education-loan-eligibility",
    },

    {
      text: "Education Loan Interest",
      link: "https://leapfinance.com/education-loan-interest-rate",
    },

    {
      text: "Education Loan Calculator",
      link: "https://leapfinance.com/education-loan-calculator",
    },

    {
      text: "Education Loan Without Collateral",
      link: "https://leapfinance.com/education-loan-without-collateral",
    },

    {
      text: "Education Loan Documents",
      link: "https://leapfinance.com/education-loan-documents",
    },

    {
      text: "Education Loan Tax Benefits",
      link: "https://leapfinance.com/education-loan-tax-benefits",
    },

    {
      text: "How to apply for Education Loan",
      link: "https://leapfinance.com/education-loan-application-process",
    },

    {
      text: "LeapScholar",
      link: "https://leapscholar.com/",
    },

    {
      text: "LeapScholar Blog",
      link: "https://leapscholar.com/blog/",
    },
  ],
  company: [
    {
      text: "Home",
      link: "https://leapfinance.com/",
    },
    {
      text: "About Us",
      link: "https://leapfinance.com/about",
    },
    {
      text: "Leap in the news!",
      link: "https://press.leapscholar.com/",
    },
  ],
  products: [
    {
      text: "Leap Scholar",
      link: "https://leapscholar.com/",
    },
    {
      text: "LeapAdvantage",
      link: "https://advantage.leapscholar.com",
    },
    {
      text: "IELTS by LeapScholar",
      link: "https://leapscholar.com/ielts-preparation-online",
    },
  ],
  socialMedia: [
    {
      icon: `${basePath}/assets/icons/twitter-icon.svg`,
      link: "https://twitter.com/leapfinancein",
      contentName: "Twitter",
    },
    {
      icon: `${basePath}/assets/icons/linkedin-icon.svg`,
      link: "https://in.linkedin.com/company/leap-global-education",
      contentName: "Linkedin",
    },
    {
      icon: `${basePath}/assets/icons/instagram-icon.svg`,
      link: "https://www.instagram.com/leap_finance/",
      contentName: "Instagram",
    },
    {
      icon: `${basePath}/assets/icons/facebook-icon.svg`,
      link: "https://www.facebook.com/leapfinanceofficial/",
      contentName: "Facebook",
    },
    {
      icon: `${basePath}/assets/icons/youtube-icon.svg`,
      link: "https://www.youtube.com/channel/UCIUFBy0KWMBxRHFiPMtXOBQ",
      contentName: "Youtube",
    },
  ],
  partners: [
    {
      link: "https://yocket.com",
      contentName: "Yocket study abroad",
    },
    {
      link: "https://geebeeworld.com",
      contentName: "GEEBEE",
    },
  ],
  testimonialLink: "https://reviews.leapfinance.com/",
  careersLink: "https://leapfinance.freshteam.com/jobs",
  legal: [
    {
      text: "Privacy Policy",
      link: "https://leapfinance.com/privacy",
    },
    {
      text: "Terms and Conditions",
      link: "https://leapfinance.com/terms",
    },
  ],
};

export const minLoanAmountByCountryNames = [
  "The minimum loan amount in NJ is $50,000.",
  "The minimum loan amount in IL is $40,000.",
  "The minimum loan amount in FL is $25,000.",
  "The minimum loan amount in NC is $15,000.",
  "The minimum loan amount in AZ is $10,000.",
];
