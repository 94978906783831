import Image from "next/image";
import Link from "next/link";
import basePath from "lib/helpers/basePath";
import React, { FunctionComponent } from "react";
import {
  footerLinks,
  minLoanAmountByCountryNames,
} from "DataMapper/Refi/LandingPageConstants";
import withWindowDimensions from "components/shared/withWindowDimensions";
import { trackClick } from "scripts/segment";
import { pageName } from "utils/events_utils";
import { companyContacts } from "lib/constants/companyContacts";

const Divider: FunctionComponent = () => (
  <div className="border-[1px] border-[#686868] w-full my-6" />
);

const ContactSection: React.FC<FooterProps> = ({ isMobileSized }) => (
  <div className="flex flex-col gap-1 break-words">
    {!isMobileSized && (
      <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
        Contact us
      </p>
    )}
    <a
      className="text-[12px] text-[#565660] leading-[21px]"
      href={companyContacts.link.phone}
    >
      Call us on: {companyContacts.display.phone} <p>(Between 10AM to 7PM)</p>
    </a>
    <a
      className="text-[12px] text-[#565660] leading-[21px]"
      href={companyContacts.link.email}
    >
      Mail us on: <p>{companyContacts.display.email}</p>
    </a>
  </div>
);

interface FooterProps {
  isMobileSized?: boolean;
}

const MainFooter = (props: FooterProps) => {
  const { isMobileSized } = props;

  return (
    <>
      <footer
        className={`bg-[#F3F3F3] ${
          isMobileSized ? "py-5 px-[22px]" : "p-[36px]"
        }`}
      >
        <div className={!isMobileSized ? "wrapper" : ""}>
          <div className="flex flex-col gap-6">
            <div className="flex items-center gap-2">
              <Image
                height={50}
                width={200}
                onClick={() => {
                  if (window) {
                    window.open("https://leapfinance.com", "_self");
                  }
                }}
                className="logo cursor-pointer"
                src={`${basePath}/assets/company_logo/new-footer-logo.svg`}
                alt="Leap Finance Logo"
              />
            </div>
            <div>
              <button
                type="button"
                onClick={() => {
                  trackClick(pageName.REFI_LANDING_PAGE, {
                    widgetName: "Footer",
                    widgetFormat: "Footer",
                    contentName: "Start Your Journey",
                    contentFormat: "Button",
                  });
                  if (window) {
                    window.open("/refinance/refi", "_self");
                  }
                }}
                className="mb-4 shadow-20 text-center rounded text-[#fff] bg-lightblue-to-blue inline-block text-sm leading-6 font-bold px-6 py-3"
              >
                Start Your Journey
              </button>
            </div>

            {/* contact-mobile */}
            {isMobileSized ? <ContactSection /> : null}

            {/* Social footerLinks */}
            <div className="flex flex-col gap-1">
              <p className="font-bold text-normal text-[#443EFF] text-[14px] md:leading-6">
                Follow us on:
              </p>
              <div className="flex gap-2 mt-2">
                {footerLinks.socialMedia.map((element, index) => (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={element.link}
                    key={`social-media-${index}`}
                  >
                    <Image
                      src={element.icon}
                      alt="social-icon"
                      width={30}
                      height={30}
                    />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="flex-1">
            <div
              className={`grid ${
                !isMobileSized ? "md:grid-cols-4" : "grid-cols-2 gap-5 mt-8"
              }`}
            >
              {/* Company */}
              <div className="flex flex-col gap-1">
                <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                  Company
                </p>
                {footerLinks.company.map((element, index) => (
                  <Link
                    href={element.link}
                    key={`company-${index}`}
                    target="_blank"
                    className="text-[12px] text-[#565660] leading-[21px]"
                  >
                    {element.text}
                  </Link>
                ))}

                {!isMobileSized && (
                  <div className="flex flex-col mt-2">
                    <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                      Our Partners
                    </p>
                    {footerLinks.partners.map((element, index) => (
                      <Link
                        href={element.link}
                        key={`partners-${index}`}
                        className="text-[12px] text-[#565660] leading-[21px]"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {element.contentName}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              {/* Products */}
              <div className="flex flex-col gap-1">
                <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                  Our Products
                </p>
                {footerLinks.products.map((element, index) => (
                  <Link
                    href={element.link}
                    key={`products-${index}`}
                    className="text-[12px] text-[#565660] leading-[21px]"
                    target="_blank"
                  >
                    {element.text}
                  </Link>
                ))}
              </div>

              <div className="flex flex-col gap-1">
                <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                  Resources
                </p>
                {footerLinks.resources.map((element, index) => (
                  <Link
                    href={element.link}
                    key={`resources-${index}`}
                    className="text-[12px] text-[#565660] leading-[21px]"
                    target="_blank"
                  >
                    {element.text}
                  </Link>
                ))}
              </div>

              <div className="flex flex-col gap-1">
                <Link
                  href={footerLinks.testimonialLink}
                  className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Testimonials
                </Link>
                <Link
                  href={footerLinks.careersLink}
                  className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </Link>
                {/* Legal */}
                <div className="flex flex-col gap-1">
                  <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                    Legal
                  </p>
                  {footerLinks.legal.map((element, index) => (
                    <Link
                      href={element.link}
                      key={`legal-${index}`}
                      className="text-[12px] text-[#565660] leading-[21px]"
                      target="_blank"
                    >
                      {element.text}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <Divider />

            <div>
              {/* Locations */}
              <div className={!isMobileSized ? "grid md:grid-cols-4" : ""}>
                <div className="flex flex-col gap-1">
                  <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                    HQ- Bangalore &nbsp;(India)
                  </p>
                  <p
                    className={`text-[13px] text-[#565660] leading-[21px] ${
                      !isMobileSized && "max-w-[148px]"
                    }`}
                  >
                    163/A, 9th Main Rd, Sector 6, HSR Layout, Bengaluru,
                    Karnataka 560102
                  </p>
                </div>

                {isMobileSized ? <Divider /> : null}

                <div className="flex flex-col gap-1">
                  <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                    Singapore &nbsp;(International)
                  </p>
                  <p
                    className={`text-[13px] text-[#565660] leading-[21px] ${
                      !isMobileSized && "max-w-[148px]"
                    }`}
                  >
                    11 Collyer Quay #17-00 The Arcade, 049317, Singapore
                  </p>
                </div>

                {isMobileSized ? <Divider /> : null}

                <div className="flex flex-col gap-1">
                  <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                    UAE &nbsp;(International)
                  </p>
                  <p
                    className={`text-[13px] text-[#565660] leading-[21px] ${
                      !isMobileSized && "max-w-[148px]"
                    }`}
                  >
                    Office 1106, Arenco Tower, Sheikh Zayed Road, <br /> Media
                    City Dubai - 3087, UAE <br /> Contact Number: +971 501501220
                  </p>
                </div>

                {isMobileSized ? <Divider /> : null}

                {!isMobileSized ? <ContactSection /> : null}

                {/* Our Partners */}
                <div className="flex flex-col gap-1 md:hidden">
                  <p className="font-bold text-normal text-[#1B1D6A] text-[14px] md:leading-6">
                    Our Partners
                  </p>
                  {footerLinks.partners.map((element, index) => (
                    <Link
                      href={element.link}
                      key={`partners-${index}`}
                      className="text-[12px] text-[#565660] leading-[21px]"
                      target="_blank"
                    >
                      {element.contentName}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <Divider />

            {/*  Eligible States */}
            <div
              className="grid gap-6 text-[12px] text-[#565660] leading-[21px]"
              id="supported-states-footer-id"
            >
              <p>© 2023, Leap Finance Inc.</p>
              <p>
                Current Eligible States: AZ, FL, GA, IL, MA, NC, NJ, NY, UT, VA,
                WI
              </p>
              <p>
                *The total time to refinance student loan depends on the
                customer profile and varies accordingly.
                <br />
                *Total amount saved depends on the customer profile and varies
                accordingly
              </p>
              <p>
                Leap Finance loans are made by Leap Finance Inc. Terms and
                conditions apply. To qualify for a Leap Finance loan you must be
                attending college in an Eligible State and must meet Leap
                Finance&apos;s underwriting requirement.
              </p>
              <div>
                {minLoanAmountByCountryNames.map((element, index) => (
                  <p key={`loan-country-${index}`}>{element}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>

      <style jsx>
        {`
          .logo {
            filter: brightness(0) invert(1);
            margin-left: -4px;
            height: 50px;
          }

          .wrapper {
            max-width: 1120px;
            margin: auto;
            display: flex;
            gap: 80px;
          }
        `}
      </style>
    </>
  );
};

export default withWindowDimensions(MainFooter);
