export function disableBodyTouch(enable?: boolean) {
  if (!enable) {
    document.body.style.touchAction = "none";
    document.body.style.pointerEvents = "none";
  } else {
    document.body.style.touchAction = "unset";
    document.body.style.pointerEvents = "unset";
  }
}

export const isClient = () => typeof window !== "undefined";
