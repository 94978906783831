import { isClient } from "lib/helpers/utils";

const useStorage = (type: Storage) => {
  try {
    const isStorageAvailable = type && !!type.setItem;
    return {
      setItem: (key: string, value: string) => {
        try {
          if (isStorageAvailable) {
            type.setItem(key, value);
          }
        } catch (error) {
          console.log(error);
        }
      },
      getItem: (key: string) => {
        try {
          if (isStorageAvailable) {
            return type.getItem(key);
          }
          return null;
        } catch (error) {
          console.log(error);
          return null;
        }
      },
      removeItem: (key: string) => {
        try {
          if (isStorageAvailable) {
            type.removeItem(key);
          }
        } catch (error) {
          console.log(error);
        }
      },
    };
  } catch (error) {
    console.log(error);
    return {
      setItem: () => {},
      getItem: () => null,
      removeItem: () => {},
    };
  }
};

export const localStorageUtils = (() => {
  try {
    return isClient()
      ? useStorage(window.localStorage)
      : null;
  } catch (error) {
    console.log(error);
    return null;
  }
})();

export const sessionStorageUtils = (() => {
  try {
    return isClient()
      ? useStorage(window.sessionStorage)
      : null;
  } catch (error) {
    console.log(error);
    return null;
  }
})();
